main.main-container {
  padding-left: var(--abyss-space-xl);
  padding-right: var(--abyss-space-xl);
  padding-bottom: var(--abyss-space-xl);
  padding-top: var(--defds-header-height);
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}
main.main-container.center-content {
  display: flex;
  justify-content: center;
}
