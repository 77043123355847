.defeds-table-container {
  overflow-x: auto;
  max-width: 100%;
  border-left: 1px solid var(--abyss-colors-gray4);
  border-right: 1px solid var(--abyss-colors-gray4);
  margin-bottom: 55px;
}

table.defeds-table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}

table.defeds-table thead {
  background-color: var(--abyss-colors-gray2);
}

table.defeds-table th {
  text-align: left;
}

table.defeds-table th,
table.defeds-table td {
  border: 1px solid var(--abyss-colors-gray4);
}
table.defeds-table th:first-child,
table.defeds-table td:first-child {
  border-left: none;
}
table.defeds-table th:last-child,
table.defeds-table td:last-child {
  border-right: none;
}

table.defeds-table th,
table.defeds-table td {
  padding: 0 10px;
  height: 50px;
  vertical-align: middle;
  text-wrap: nowrap;
}

table.defeds-table tr:nth-child(even) {
  background-color: var(--abyss-colors-gray1);
}
