.dfedsChart,
.dfedsChart * {
  box-sizing: border-box;
}

.dfedsChart {
  display: flex;
  gap: 0rem;
  /* Allow for a-axis labels */
  margin-bottom: var(--dfeds-x-axis-label-allowance);
}

.dfedsChartHeading {
  font-weight: bold;
  text-align: center;
}
