main#eventSearchPage.main-container {
  margin: 0;
  padding: 0;
}

#eventSearchPage #breadcrumbs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#eventSearchContent {
  display: grid;
  grid-template-columns: minmax(350px, 1fr) 5fr;
}

#eventSearchContent #eventSearchResults {
  padding-top: var(--defds-header-height);
  overflow-x: auto;
  padding-left: var(--abyss-space-xl);
  padding-right: var(--abyss-space-xl);
}

#eventSearchContent #filterPanel {
  box-sizing: border-box;
  padding: calc(1rem + var(--defds-header-height)) 1rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: var(--abyss-space-xl);
  padding-right: var(--abyss-space-xl);
  min-height: 100vh;
  background-color: var(--abyss-colors-core-color-warmWhite-10);
}

/* #region icon and label, non results states */
#eventSearchContent .resultsPending,
#eventSearchContent .resultsSearching,
#eventSearchContent .resultsNone,
#eventSearchContent .resultsError,
.eventSearchEventDetailsResults .resultsSearching,
.eventSearchEventDetailsResults .resultsError {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--defds-header-height));
}

#eventSearchContent .resultsPending .iconLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
}

#eventSearchContent .resultsPending .iconLabelLabel {
  font-weight: bold;
}

#eventSearchContent .labelResultsSearching,
#eventSearchContent .resultsNone .labelResultsNone,
#eventSearchContent .resultsError .labelResultsError,
#eventSearchContent .eventSearchLoadingMore .eventSearchLoadingMoreLabel,
.eventSearchEventDetailsResults .resultsSearching .labelResultsSearching,
.eventSearchEventDetailsResults .resultsError .labelResultsError {
  margin-left: 1rem;
  font-weight: bold;
}

/* #endregion */

#eventSearchContent .eventSearchLoadingMore {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

/* #region Filter Panel */
#eventSearchBtn {
  margin-top: 1rem;
}
/* #endregion */

/* #region chart tooltip */
#eventSearchPage .dfedsTooltip {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: white;
  padding: 1rem;
  position: absolute;
  z-index: 5;
}

#eventSearchPage .dfedsTooltip .dfedsTooltipHeader {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

#eventSearchPage .dfedsTooltip .dfedsTooltipHeader {
  font-weight: bold;
  margin-bottom: 0.2rem;
}
#eventSearchPage .dfedsTooltip .dfedsTooltipEventItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

#eventSearchPage
  .dfedsTooltip
  .dfedsTooltipEventItem
  .dfedsTooltipEventItemLabel {
  white-space: nowrap;
}

#eventSearchPage
  .dfedsTooltip
  .dfedsTooltipEventItem
  .dfedsTooltipEventItemValue {
  font-weight: bold;
}
/* #endregion chart tooltip */

/* #region TABLE */
#eventSearchPage .defds-table {
  font-size: 9px;
}
#eventSearchPage .eventCell {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  align-items: center;
}
#eventSearchPage .eventCell .eventCellBtn {
  color: var(--abyss-colors-button-color-text-root-tertiary-default);
  margin: 0;
  padding: 0;
  font-weight: var(--abyss-fontWeights-button-fontWeight-root);
}
#eventSearchPage .eventCell .eventCellBtn:hover {
  border-color: var(--abyss-colors-button-color-border-root-tertiary-hover);
}
#eventSearchPage .eventTypeColorIndicator {
  border-radius: 4px;
  width: 18px;
  height: 18px;
}

#eventSearchPage .eventSearchTableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#eventSearchPage .eventSearchTableControls .showingCounts {
  font-size: var(--abyss-fontSizes-pagination-fontSize-resultCount);
}
/* #endregion TABLE */

/* #region table results */
#eventSearchContent .eventSearchTableResults .resultsError,
#eventSearchContent .eventSearchTableResults .resultsSearching {
  min-height: 10rem;
}
/* #endregion table results */

/* #region EVENT DETAILS */
.eventSearchEventDetailsResults .eventItemDetailsContainer {
  padding: 1rem;
}

.eventSearchEventDetailsResults .eventItemDetailsContainer table,
.eventSearchEventDetailsResults .eventItemDetailsContainer table td,
.eventSearchEventDetailsResults .eventItemDetailsContainer table th {
  border: none;
}
.eventSearchEventDetailsResults .eventItemDetailsContainer table {
  width: fill;
}
.eventSearchEventDetailsResults .eventItemDetailsContainer table td,
.eventSearchEventDetailsResults .eventItemDetailsContainer table th {
  height: 35px;
}
.eventSearchEventDetailsResults .eventItemDetailsContainer table th {
  text-align: left;
  font-weight: normal;
}
.eventSearchEventDetailsResults .eventItemDetailsContainer table td {
  font-weight: bold;
}

.eventSearchEventDetailsResults .abyss-modal-content-title {
  color: red;
}
/* #endregion EVENT DETAILS */

/* #region event search column selection */
.eventSearchColumnSelector {
  padding: 1rem;
}
.eventSearchColumnSelector .eventSearchColLocked,
.eventSearchColumnSelector .eventSearchColToggle,
.eventSearchColumnSelector .eventSearchColHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--abyss-colors-gray3);
  background-color: var(--abyss-colors-gray1);
  height: 48px;
}
.eventSearchColumnSelector .eventSearchColHeader {
  color: var(--abyss-colors-gray7);
  background-color: var(--abyss-colors-gray3);
  font-weight: bold;
  border-bottom: none;
  padding-left: 5px;
}

.eventSearchColumnSelector .eventSearchColToggle {
  cursor: pointer;
}

.eventSearchColumnSelector .eventSearchColumnSelectorIcon {
  min-width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.eventSearchColumnSelector .eventSearchColumnSelectorInstructions {
  white-space: pre-line;
  margin-bottom: 1rem;
}

.eventSearchColumnSelector .eventSearchColumnSelectorToggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0px;
}
/* #endregion event search column selection */
