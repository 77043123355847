/* #region  Y-AXIS HEADER */
.dfedsChart .yAxisHeader {
  flex-grow: 0;
  display: flex;
  align-items: center;
  width: 30px;
  translate: -20px 0;
}
.yAxisHeaderContent {
  transform: rotate(-90deg);
  font-weight: bold;
}
/* #endregion Y-AXIS HEADER */

/* #region Y-AXIS */
.dfedsChart .yAxis {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dfedsChart .yAxis .dfedsGridLine {
  border-top: 1px solid var(--abyss-colors-gray6);
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1px solid var(--abyss-colors-gray6);
}

.dfedsChart .yAxis .dfedsGridLine .dfedsGridLineLabel {
  background-color: white;
  padding: 0px 10px;
  translate: -10px -10px;
  text-align: right;
}

.dfedsChart .yAxis.hideNonIntegerNumbers .dfedsGridLine.nonIntegerNumber {
  border-top-color: white;
}
.dfedsChart
  .yAxis.hideNonIntegerNumbers
  .dfedsGridLine.nonIntegerNumber
  .dfedsGridLineLabel {
  visibility: hidden;
}
/* #endregion Y-AXIS */
