#logoutPageContent {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#logoutMessage {
  margin-top: 10rem;
  text-align: center;
  font-size: var(--abyss-fontSizes-xs);
  max-width: var(--dfeds-max-paragraph-width);
}
