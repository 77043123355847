#mainHeader {
  width: 100%;
  padding: 0 var(--abyss-space-xl);
}
#mainHeader #headerContent {
  height: var(--defds-header-height);
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}
#mainHeader #logoLink {
  padding-top: 5px;
}
#mainHeader #accountSection {
  margin-top: 7px;
}

#mainHeader .headerRight {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

#mainHeader .headerRight button span {
  font-weight: var(--abyss-fontWeights-medium);
}
