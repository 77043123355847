[data-loading='true'] .abyss-select-input-input:after,
[data-loading='true'] .abyss-select-input-multi-no-options-selected:after {
  margin-left: 1px;
  text-align: left;
  content: '';
  animation: animatedEllipsis 2s ease-out infinite;
}

@keyframes animatedEllipsis {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}
