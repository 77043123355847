#homePageContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  margin: 5rem auto;
  max-width: calc(900px + 2rem);
}

#homePageContent #navigationGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 1rem;
  justify-content: center;
}

/* #region a.trayLink */
#homePageContent .trayLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--abyss-colors-gray4);
  border-radius: 15px;
  height: 140px;
  padding: 10px;
  transition: all 200ms ease;
  text-decoration: none;
}

#homePageContent .trayLink svg {
  fill: var(--abyss-colors-foreground);
}

#homePageContent .trayLink .trayTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  color: var(--abyss-colors-foreground);
  font-weight: var(--abyss-fontWeights-medium);
}
/* #endregion */

/* #region a.trayLink:hover */
#homePageContent .trayLink:hover {
  background: var(--abyss-colors-interactive3);
  border-color: var(--abyss-colors-primary1);
}

#homePageContent .trayLink:hover svg {
  fill: var(--abyss-colors-primary1);
}

#homePageContent .trayLink:hover .trayTitle {
  color: var(--abyss-colors-primary1);
}
/* #endregion */
