.dfedsChart .dfedsBar {
  flex-grow: 1;
  background-color: var(--abyss-colors-core-color-primaryDvz1); /*#15a796;*/
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0px 0.5%;
  max-width: 8%;
  z-index: 2;
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s linear, height 0.2s ease-out;
}

.dfedsChart .dfedsBar.noBarSelected {
  opacity: 1;
}

.dfedsChart .dfedsBar.barDeselected {
  opacity: 0.3;
}
